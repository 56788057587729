import { Link, useLocation } from "react-router-dom";
import { useAccount, useBalance, useEnsAddress, useEnsName, useEstimateFeesPerGas, useGasPrice } from "wagmi";
import getENV from "../../../../util/getENV";
import useMerkle from '../../../../hooks/useMerkle';
import SearchBox from "./elements/SearchBox";
import defined from "../../../../util/defined";
import { ethers } from "ethers";
import { useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ConnectButton from "./elements/ConnectButton";
import equiv from "../../../../util/equiv";
import useSkelephunks from "../../../../hooks/ethereum/nft/skele/useSkelephunks";
import useSkeleKeys from "../../../../hooks/ethereum/nft/skele/useSkeleKeys";
import useCatacombs from "../../../../hooks/ethereum/nft/skele/useCatacombs";
import useObituary from "../../../../hooks/ethereum/nft/skele/useObituary";
import useSkeleDrop from "../../../../hooks/ethereum/nft/skele/useSkeleDrop";
import { mainnet } from "viem/chains";

export default function Header({ subtitle }) {
    //console.log('>>> Header')
    const { override, testnet, wagmi: { config: wagmiConfig }, modules, promo: { drop: dropPromo } } = getENV();
    const account = useAccount();
    let isConnected, wallet
    if (override) {
        isConnected = true;
        wallet = override
    } else {
        isConnected = account.isConnected;
        wallet = account.address;
    }
    const { skelephunks } = useSkelephunks();
    const { drop } = useSkeleDrop();
    const { keys } = useSkeleKeys();
    const gasPrice = useEstimateFeesPerGas({
        config: wagmiConfig,
        formatUnits: 'gwei',
    })?.data?.formatted?.maxFeePerGas;
    const { data: balanceData, status: balanceStatus } = useBalance({ config: wagmiConfig, address: wallet });
    const walletBalance = defined(balanceData) ? Number(balanceData?.formatted).toPrecision(4) : "";
    const { data: ensData, status: ensStatus, error: ensError } = useEnsName({ address: wallet, chainId: 1 });

    const { obituary } = useObituary();
    let hasProfile, profileTag, tokenURI;
    if (modules.obituary.address) {
        profileTag = obituary.getName(wallet);
        hasProfile = obituary.hasIdentity(wallet);
        const profileToken = hasProfile ? obituary.getToken(wallet) : null;
        tokenURI = profileToken ? catacombs.getMetadata(profileToken)?.image : '';
    }

    const displayName = hasProfile ? profileTag : ensData?.split('.eth')[0] || null;
    const numMinted = skelephunks.numMinted(wallet);
    const numTokens = skelephunks.balanceOf(wallet);

    const { catacombs } = useCatacombs();
    let numLocked = 0;
    if (modules.catacombs.address) {
        numLocked = catacombs.balanceOf(wallet);
    }
    const numKeys = keys.balanceOf(wallet);
    const mintStatus = skelephunks.mintStatus();
    const { drops: { proof } } = useMerkle(wallet);
    const numDrops = drop.claimableDropsForWallet(wallet, proof);
    const route = useLocation().pathname;
    const maxMinted = defined(numMinted, mintStatus) && mintStatus < 4 && numMinted > 12;
    const hasInventory = numTokens + numLocked + numKeys > 0;
    const returnVisitor = hasInventory || numMinted > 0;

    // const mintPhases = ['pre', 'claim', 'list', 'pub', 'zoo', 'post'];
    const minted = defined(numMinted) && numMinted > 0;
    const { open } = useWeb3Modal();
    const ensureConnection = () => {
        if (!isConnected) open();
    }
    const [navShown, setNavShown] = useState();
    const toggleNav = () => {
        setNavShown(!navShown);
    }
    return (<>
        <div className="title">
            <div className="blurb welcome">
                <div onClick={toggleNav} className={`shownav navcontrol`}>
                    <div className="text-block-15">{navShown ? 'HIDE' : 'SHOW'} NAVIGATION</div>
                </div>
                <div className="id-lockup">
                    {hasProfile &&
                        <Link to="/profile">
                            <div className="avatar">
                                <img src={tokenURI} />
                            </div>
                        </Link>
                    }
                    <div className="salutation" >
                        <div>welcome {returnVisitor ? 'back ' : ''}</div>
                        <div>{displayName ? <span className="identity">{displayName}</span> : 'Anon'} </div>
                    </div>
                </div>
                <div className={navShown ? '' : 'hide-small'}>
                    {route !== '/mint' &&
                        <div>
                            <Link onClick={ensureConnection} className={`yours ${navShown ? '' : 'navlink'}`} to="/mint">🔥 Mint Skelephunks</Link>
                        </div>
                    }
                    {route !== '/inventory' && isConnected && hasInventory &&//preload Tokens
                        <div>
                            <Link onClick={() => skelephunks.tokensOfOwner(wallet)} className={`yours ${navShown ? '' : 'navlink '}`} to="/inventory">💀 your collection</Link>
                        </div>
                    }
                    {route !== '/graveyard' &&
                        <div>
                            <Link className={`yours ${navShown ? '' : 'navlink'}`} to="/graveyard">🪦 Visit Graveyard</Link>
                        </div>
                    }
                    {route !== '/drop' && (!isConnected || defined(numDrops)) && (numDrops > 0 || defined(dropPromo)) &&
                        <div>
                            <Link className={`free navlink`} to="/drop">💧 {defined(dropPromo) && (!isConnected || (defined(numDrops) && numDrops == 0)) ? dropPromo.nav : `You Have ${numDrops > 1 ? numDrops : 'a'} drop${numDrops > 1 ? 's' : ''}`}</Link>
                        </div>
                    }
                    <div className={`yours ${navShown ? '' : 'navlink'}`}>
                        {route != '/drop' && <SearchBox hidden={false} />}
                    </div>
                </div>
            </div>
            <div className="banner">
                <Link className="" to="/">
                    <img
                        src="/images/SKELEPHUNKS_lockup.png"
                        loading="lazy"
                        alt="Skelephunks"
                        className="image"
                        data-w-id="31a62934-7dee-bf92-5793-1bf374967db9"
                    />
                </Link>

                <h3 className="mint-title" data-w-id="b93b5472-5113-27f5-b1ae-1a2a5a5a52c2">
                    {subtitle}
                </h3>
            </div>
            <div className="blurb wallet">
                {isConnected && <>
                    <div className="address">
                        {wallet.substring(0, 13)}
                    </div>
                    {maxMinted ?
                        <div className="yours">Max Minted</div> : <div className="yours">{walletBalance} {equiv(testnet, 'goerli') ? 'g' : ''}{balanceData?.symbol}</div>
                    }
                </>}
                {defined(gasPrice) && <div className="info eth">
                    Gas is {gasPrice.substring(0, 4)}  GWEI
                </div>}
                <div className="wc-button">
                    <ConnectButton />
                </div>
                <div className="iconnav">
                    <a href="https://discord.gg/YS7FgPudTr" target="_blank">
                        <img src="images/28-283551_discord-icon-png.png" loading="lazy" alt="" className="social" />
                    </a>
                    <a href="https://opensea.io/collection/skelephunks-official" target="_blank">
                        <img src="images/OS.png" loading="lazy" alt="" className="social" />
                    </a>
                    <a href="https://twitter.com/skelephunks" target="_blank">
                        <img src="images/2021-Twitter-logo---white.png" loading="lazy" alt="" className="social" data-ix="new-interaction" />
                    </a>
                </div>
            </div>
        </div>
    </>)
}