import { readContract, writeContract, waitForTransactionReceipt } from '@wagmi/core'
import defined from '../../util/defined.js';

import equiv from "../../util/equiv.js";
import getENV from '../../util/getENV.js';
import useRedraw from '../useRedraw.js';

/**
 * Process the result of a contract function call based on its output type.
 * @param {any} result The result of the contract function call.
 * @param {object} output The output type of the contract function.
 * @returns {any} The processed result.
 */

const out = (result, output) => {
    // Check if result is defined
    if (!defined(result)) return
    let Cast;
    // Determine the type of result and cast accordingly
    if (output.type.indexOf('int') + 1) {
        Cast = Number;
    } else {
        switch (output.type) {
            case 'address':
                Cast = String;
                break;
            case 'bool':
                Cast = Boolean;
                break;
            default:
                Cast = String;
                break;
        }
    }


    return Cast ? Cast(result) : result;
}

// Object to store contract instances and transactions
const contracts = {};
let instances = {};
const transactions = {};

/**
 * Custom hook to interact with smart contracts.
 * @param {object} param0 An object containing the contract address and ABI.
 * @returns {object} An object containing contract functions and transaction information.
 */
export default function useSmartContract({ address, abi }) {
    // //console.log('>>> useSmartContract')

    // Initialize redraw function
    const { redraw } = useRedraw();

    // Get environment configuration
    const { wagmi: { config: wagmiConfig } } = getENV();

    // on first render, create an instance of each contract each time this hook is called
    // instances[address] = instances[address] || 0;// if no instances of this address yet yet initialize the count to 0; 
    // const inst = instances[address];
    // const instance = useMemo(() => ++instances[address], []);
    // if (instance > inst) {
    //     console.log(`creating instance of ${address}, total is now ${instance}`);
    // }

    // Initialize contract object
    let contract = contracts[address] || {//one contract object per address
        address,
        abi: {},
        reads: [],//reads -- separate read queue per instance 
        results: {},//results[functionName][args-concat] -- shared results queue
        refresh: false,//contract -- refresh flag per instance, to create reads in correct queue
        transactions: {}
    };

    // If functions for the current instance are not defined, create them
    if (!defined(contract.functions)) {
        contract.functions = {}; // create instance function holder
        abi.forEach(({ name, type, stateMutability, inputs, outputs }) => {
            switch (type) {
                case 'function':// for each function in the ABI, create a function in the that will write to the contract results queue
                    const inlen = inputs.length;
                    contract.reads = contract.reads || []
                    if (!defined(contract.functions[name])) {// if the contract doesnt have a function by this name
                        contract.functions[name] = (...args) => {//create a universal function that branches according to the function type when called
                            // TODO: handle multiple function signatures somehow? thte copde below expects only one definition per function
                            // if (args.length !== inlen) {
                            //     throw new RangeError(`Incorrect number of arguments passed to function ${name}. (Expecting ${inputs.length})`)
                            // }
                            const key = args.join(',');//create arguments key to caching results

                            switch (stateMutability) {
                                // view and pure are read functions. return cached value or call to the contract, cache the result, and listen for changes
                                case 'view':
                                case 'pure':
                                    //require all arguments to be defined
                                    if (inputs.length > 0 && !defined(...args)) {
                                        return
                                    }
                                    //ensure a result container exists for every named function
                                    contract.results[name] = contract.results[name] || {};
                                    //reuse existing results from cache if exists, other
                                    if (!defined(contract.results[name][key])) {
                                        //create holder object
                                        contract.results[name][key] = {};
                                        //define the read config
                                        const read = { address, abi, functionName: name, args };
                                        contract.reads.push(read);//add the read to the instance read queue

                                        readContract(wagmiConfig, read).then((value) => {//perform the read

                                            //console.log(`success reading <${address.substring(2, 10)}>${name}(${key}):${value}`)
                                            if (contract.results[name][key]?.value !== value) { //if new value
                                                contract.results[name][key].value = value;//store the value 
                                                contract.refresh = true;//flag the instance for a refresh
                                            }
                                        }).catch((error) => {
                                            // Handle contract read error
                                            //console.log(`error reading <${address?.substring(2, 10)}>${name}(${key}):${error}`)//.split('\n')[0]}`)
                                            redraw(true)
                                        }).finally(() => {
                                            contract.reads.splice(contract.reads.indexOf(read), 1)//remove read from queue
                                            if (contract.reads.length === 0 && contract.refresh) {//if flagged for refresh and the queue is empty
                                                contract.refresh = false;//clear refresh
                                                redraw();//trigger a react redraw
                                            }
                                        });
                                    }
                                    const output = out(contract.results[name][key].value, outputs[0]);//output value cast to correct type or undefined

                                    return output
                                //payable and nonpayable are write functions. prepare thte write and return an object that lets it be called and monitored
                                case 'payable':
                                case 'nonpayable':
                                    contract.transactions[name] = contract.transactions[name] || {};//ensure container object for function transactions
                                    let transaction = contract.transactions[name][key];//used stored transaction if exists
                                    if (!defined(transaction)) {// if there isnt a stored transaction for this function and agruments, build it up
                                        const write = {
                                            abi,
                                            address,
                                            functionName: name,
                                            args
                                        }
                                        transaction = { write, status: 'idle' };//transaction holds write config, status and callback
                                        // disabled transactions contain no-op methods to maintain logical flow with no effect
                                        const disabled = {
                                            ...transaction,
                                            write: () => {
                                                //console.log(`${name}(${key}) disabled`);
                                            },
                                            config: () => {
                                                return {
                                                    ...transaction,
                                                    write: () => {
                                                        //console.log(`${name}(${key}) disabled`);
                                                    },
                                                }
                                            }
                                        }
                                        //disable transactions with undefined arguments
                                        if (inputs.length > 0 && !defined(...args)) {
                                            return disabled
                                        }
                                        //write is a callback to trigger the contract write - it internally manages the status of the transaction
                                        transaction.write = () => {
                                            //console.log(`writing (${key}) to ${name}`);
                                            //write to contract

                                            //console.log(`calling writecontract ${name}`);
                                            writeContract(wagmiConfig, write).then(hash => {
                                                // on success, update status to "success" and redraw
                                                //console.log(`waiting for tx ${hash}`);
                                                waitForTransactionReceipt(wagmiConfig, { hash, chainId: wagmiConfig.chainId }).then(() => {
                                                    //console.log('success!')
                                                    contract.transactions[name][key] = { ...transaction, status: 'success' };
                                                    write.onSuccess?.();
                                                    redraw();
                                                });
                                                //while waiting, update status to "loading" and redraw
                                                contract.transactions[name][key] = { ...transaction, hash, status: 'loading' };
                                                redraw();
                                            }).catch(e => { //on error, update status to "error" and redraw
                                                e.reason = e.shortMessage.split(':\n').pop();
                                                contract.transactions[name][key] = { ...transaction, status: 'error', error: e };
                                                //console.log(`tx error:${e.reason}`);
                                                redraw();
                                            })
                                            // when triggered, update status to "signing" and force redraw to update UI before wallet opens
                                            contract.transactions[name][key] = { ...transaction, status: 'signing' };

                                            contract.transactions[name][key] = { ...transaction, status: 'signing' };
                                            redraw(true);//true forces imediate redraw
                                        };
                                        //config is a chained function on the transaction object that facilitates explicit disabling, gas estimation, payable value, and onSuccess callbacks
                                        transaction.config = ({ enabled, gas, value, onSuccess }) => {
                                            if (equiv(enabled, false)) return disabled
                                            write.gas = gas;
                                            write.value = value;
                                            write.onSuccess = () => {
                                                //console.log('success!'); onSuccess?.()
                                            }
                                            return contract.transactions[name][key]
                                        }
                                        //store the prepared transaction object for 
                                        contract.transactions[name][key] = transaction;
                                    }
                                    //return it
                                    return transaction;
                            }
                        }
                    } //else console.log(`contract method ${name} exists on ${address}[${instance}]`);
                    break;
                case 'event':
                //do this once only per contract address

            }
        });
        //watch for events, when any event gets logged, report event log to console and redraw 
        // watchContractEvent(wagmiConfig, {
        //     address,
        //     abi,
        //     onLogs: (logs) => {
        //         console.log(`>>>EVENTS: @ ${address.substring(0, 4)}..${address.substring(address.length - 2, 2)} ${logs}`)
        //         redraw();
        //     },
        //     onError: (error) => {
        //         console.log(`>>>EVENT ERROR: @ ${address.substring(0, 4)}..${address.substring(address.length - 2, 2)} ${error}`)
        //     },
        // })
    }
    // store contract object by address
    contracts[address] = contract;
    // 
    //return the fields of the  contract object, a clear() callback for resetting the results cache for that contract object,
    // and the object holding functions of the current instance as "contract" (call like 'contract.functionName(args)')

    return { ...contract, clear: () => { contract.results = {}; }, contract: contract.functions, ext: contract }
}