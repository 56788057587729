//
// Author: Skelephunks 
//
import Mint from './screens/Mint.js';
import Inventory from './screens/Inventory.js';



import getENV from '../../util/getENV.js';
import Profile from './screens/Profile.js';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Token from './screens/Token.js';
import Wallet from './screens/Wallet.js';
import Graveyard from './screens/Graveyard.js';
import Drop from './screens/Drop.js';
import defined from '../../util/defined.js';
import { useAccount, useFeeData, useSwitchChain } from 'wagmi';
import useMerkle from '../../hooks/useMerkle.js';
import equiv from '../../util/equiv.js';
import getRedirect from '../../util/getRedirect.js';
import useSkelephunks from '../../hooks/ethereum/nft/skele/useSkelephunks.js';
import Tokens from './screens/modules/Tokens.js';
import { useEffect } from 'react';
import usePageTracking from '../../hooks/usePageTracking.js';
import Header from './screens/modules/Header.js';


let rootsShown = false;
let proofsShown = false;
export default function AppRouter() {
  //console.log('>>> AppRouter')

  const { override } = getENV();
  const { skelephunks } = useSkelephunks()
  const account = useAccount();
  let wallet
  if (override) {
    wallet = override
  } else {
    wallet = account.address;
  }
  const { mint, drops } = useMerkle(wallet);
  if (equiv(window.location.hostname, 'localhost')) {
    if (defined(mint.roots?.[0], mint.roots?.[1], drops.root) && !rootsShown) {
      console.log(`PC ROOT: ${mint.roots[0]}`);
      console.log(`AL ROOT: ${mint.roots[1]}`);
      console.log(`DROPS ROOT: ${drops.root}`);
      rootsShown = true;
    }
    if (defined(wallet) && !proofsShown) {
      console.log(`MINT PROOFS for ${wallet}: ${JSON.stringify(mint?.proofs).replaceAll('"', '')}`);//
      console.log(`DROP PROOF for ${wallet}: ${JSON.stringify(drops?.proof).replaceAll('"', '')}`);//
      proofsShown = true;
    }
  }
  getRedirect({
    mint: 'mint',
    my: 'inventory',
    graveyard: 'graveyard',
    drop: 'drop/',
    token: 'token/',
    wallet: 'wallet/'
  })


  const { mint: { proofs: merkleProofs } } = useMerkle(wallet);
  const maxMintsForWallet = skelephunks.maxMintsPerWallet(wallet, ...merkleProofs)
  const mintStatus = skelephunks.mintStatus();
  const numMinted = skelephunks.numMinted(wallet);
  const maxMinted = defined(maxMintsForWallet, numMinted, mintStatus) && mintStatus < 4 && numMinted > maxMintsForWallet;

  return (<>

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to={maxMinted ? '/inventory' : '/mint'} />} />
        <Route path='/mint' element={<Mint />} />
        <Route path='/drop' element={<Drop />} />
        <Route path="/drop/:walletID" element={<Drop />} />
        <Route path='/inventory' element={<Inventory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/:walletID" element={<Wallet />} />
        <Route path="/token/:tokenID" element={<Token />} />
        <Route path="/tokens/:tokens" element={<Tokens />} />
        <Route path="/wallet/:walletID" element={<Wallet />} />
        <Route path="/graveyard" element={<Graveyard />} />
      </Routes>
    </BrowserRouter>
  </>)
}
